@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-Light.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-Italic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: bold;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 900;
  src: url("../public/assets/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf");
}
