.sidebar {
  font-weight: 500;
  background-color: $brand-color;
  display: flex;
  flex-flow: column;
  width: 235px;
  min-width: 235px;
  max-width: 235px;
  padding: 10px 0;
  align-items: center;
  z-index: 1;

  .logo {
    max-width: 124px;
    width: 100%;
    margin-bottom: 10px;
  }

  .sidebar-items {
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow-y: auto;

    hr {
      margin: 1rem 0.5rem;
    }

    .sidebar-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 20px;
      color: rgba(242, 242, 2442, 0.75);
      font-weight: 500;

      i {
        margin-right: 15px;
        width: 22px;
        text-align: center;
      }

      &.is-active {
        color: white;
        background: rgba(242, 242, 242, 0.1);
      }

      &:hover {
        color: white;
      }
    }

    .sidebar-subheader {
      padding: 25px 20px 5px;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      color: rgba(242, 242, 242, 0.5);
    }
  }

  &:not(.open) {
    width: auto;
    min-width: auto;
    max-width: none;

    .logo {
      width: 35px;
      margin-bottom: 1rem;
    }

    .sidebar-items {
      .sidebar-item {
        i {
          margin-right: 0;
          padding: 0.25rem 0;
        }
      }
    }
  }
}
