.ipr-form {
  .page-title {
    text-align: center;
  }

  form {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .field {
      width: auto;
      margin-bottom: 1rem;

      &.is-full {
        width: 100%;
      }

      label {
        font-size: 0.875rem;
        color: $gray-brand;
        font-weight: normal;
      }
    }

    .help-text {
      font-size: 0.875rem;
      font-weight: normal;
      font-style: italic;
      color: $gray-65-brand;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .item-table {
    th {
      font-weight: normal;
      color: $gray-65-brand;
      font-size: 0.875rem;
      border-bottom: none;
      vertical-align: bottom;
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }

    td {
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }

    .field {
      margin-bottom: 0;
    }

    .remove-btn {
      padding: 0;
      color: $danger;
      font-size: 1.5rem;
      height: auto;
      background: transparent;
      border: none;

      &:hover {
        color: darken($danger, 10%);
      }
    }
  }

  .subtitle {
    font-size: 1.125rem;
    text-align: center;
    color: $gray-65-brand;
    font-weight: lighter;

    b {
      font-weight: 500;
    }
  }

  .content {
    max-width: 50rem;
    margin: auto;
    width: 100%;
    padding: 0 0.5rem;
    position: relative;

    .steps {
      list-style: none;

      li + li {
        margin-top: 0;
      }
    }

    .table tr:hover {
      background: initial;
    }
  }

  .field {
    label {
      color: $gray-brand;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .card {
    display: flex;
    flex-flow: column;
    padding: 1.5rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    .card-header {
      display: flex;
      flex-flow: column;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $gray-25-brand;

      .ipr-number {
        font-size: 1.75rem;
        font-weight: 300;
        flex: 1;
        padding: 0;
        margin-bottom: 0;
      }

      .created-date {
        font-style: italic;
        font-weight: 300;
        color: $gray-65-brand;
      }

      .created-by {
        font-weight: 300;
        color: $info;
        font-size: 1.25rem;
      }
    }

    .card-body {
      display: flex;
      flex-flow: column;
      padding-top: 1.5rem;

      .top-fields {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: start;
      }

      .data-field {
        display: flex;
        flex-flow: column;
        align-items: start;
        margin-bottom: 1.5rem;

        span {
          font-weight: 500;
          font-size: 0.875rem;
          color: $gray-65-brand;
        }

        p {
          font-weight: 500;
          font-size: 1.15rem;
          line-height: 1.5rem;
          color: $gray-brand;
          display: block;
          margin-bottom: 0;

          &.dirty {
            color: $dirty;

            span {
              color: $dirty;
            }
          }
        }
      }

      .data-field-label {
        font-weight: 500;
        font-size: 0.875rem;
        color: $gray-65-brand;
      }

      .product-table {
        thead {
          tr {
            th {
              vertical-align: bottom;
              font-weight: 500;
              font-size: 0.875rem;
              color: $gray-65-brand;
              padding: 0 0.5rem 0.5rem;
              border-bottom: 1px solid $gray-25-brand;

              &:first-child { padding-left: 0; }
              &:last-child { padding-right: 0; }
            }
          }
        }

        td {
          font-weight: 500;
          font-size: 1rem;
          color: $gray-brand;
          padding: 0.5rem;

          &:first-child { padding-left: 0; }
          &:last-child { padding-right: 0; }
        }

        .number-cell {
          text-align: right;
        }

        &.dirty {
          td {
            color: $dirty;
          }
        }
      }
    }
  }

  .ipr-actions {
    margin-top: 1rem;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    .button {
      margin-left: 0.5rem;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

      &:first-child { margin-left: 0; }
    }

    .help-text {
      font-size: 0.875rem;
      font-style: italic;
      color: $gray-65-brand;
    }
  }

  .ipr-status {
    position: relative;
    top: unset;
    right: unset;
    margin-left: 1rem;
    min-width: 25%;

    .status-step {
      margin-bottom: 0.75rem;

      &:last-child { margin-bottom: 0; }

      &.pending {
        color: $gray-65-brand;
        font-size: 1.125rem;
      }

      &.active {
        font-weight: 600;
        color: $info;
      }

      i {
        min-width: 1.5rem;
        text-align: center;
      }

      .timestamp {
        color: $gray-65-brand;
        font-weight: 300;
        font-style: italic;
        font-size: 0.875rem;
      }

      .details {
        display: flex;
        flex-flow: row;
        align-items: baseline;
        font-size: 1.125rem;

        .approver {
          font-weight: 300;
          font-style: italic;
          color: $info;
          font-size: 0.875rem;
        }
      }
    }
  }

  .ipr-display {
    display: flex;
    align-items: flex-start;
    max-width: 90%;
    margin-top: 1rem;
    margin-bottom: 5rem;

    .nt {
      flex: 1;
    }

    .field {
      .textarea,
      .input,
      .select select {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
      }
    }
  }

  .ipr-content {
    margin-left: auto;
    min-width: 40%;
  }
}

.ipr-entry-list {
  .page-subtitle {
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 1rem;

    i {
      font-size: 24px;
    }
  }

  .ipr-list {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    width: calc(100% + 4rem);
    overflow-x: auto;
    padding: 0 2rem 1rem;
    margin-left: -2rem;
    margin-right: -2rem;

    .empty-spacer {
      min-width: 0.05rem;
    }
  }

  .ipr-card {
    background: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-width: 356px;
    max-width: 356px;
    width: 356px;

    &:hover {
      cursor: pointer;
    }

    .ipr-card-header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: row;
      padding: 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: white;

      &.ipr-state-unsubmitted {
        background: $gray-65-brand;
      }

      &.ipr-state-submitted,
      &.ipr-state-pending,
      &.ipr-state-approved,
      &.ipr-state-shipped {
        background: $info;
      }

      &.ipr-state-completed {
        background: $primary;
      }

      &.ipr-state-rejected {
        background: $danger;
      }

      .ipr-card-title {
        flex: 1;
        display: flex;
        flex-flow: row;
        margin-right: 2rem;
        align-items: center;

        .ipr-num {
          font-size: 1.25rem;
          line-height: 1rem;
        }

        .rma-num {
          flex: 1;
          padding-left: 0.25rem;
          font-style: italic;
          font-weight: 300;
          font-size: 0.875rem;
        }
      }

      .ipr-state {
        i { margin-right: 0.25rem; }
      }
    }

    .ipr-card-body {
      padding: 10px 15px 15px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .ipr-customer {
        font-size: 20px;
        color: $gray-brand;
      }

      .created-by {
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        color: $gray-65-brand;
      }

      .info-group {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 0.5rem;
      }

      .ipr-info {
        display: flex;
        flex-flow: row;
        align-items: center;

        i {
          font-size: 24px;
          margin-right: 0.5rem;
        }

        &.ipr-state-unsubmitted i {
          color: $gray-65-brand;
        }

        &.ipr-state-submitted i,
        &.ipr-state-pending i,
        &.ipr-state-approved i {
          &.fa-check-circle { color: $primary; }
          &.fa-clock { color: $info; }
        }

        &.ipr-state-rejected i {
          color: $danger;
        }

        &.ipr-state-shipped i {
          color: $info;
        }

        &.ipr-state-completed i {
          color: $primary;
        }

        .ipr-info-details {
          display: flex;
          flex-flow: column;

          .name {
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1610px) {
  .ipr-form {
    .ipr-display {
      display: flex;
      align-items: flex-start;
      max-width: 90%;

      .nt {
        flex: 1;
      }

      .ipr-status {
        position: relative;
        top: unset;
        right: unset;
        margin-left: 1rem;
        min-width: 25%;
      }

      .ipr-content {
        margin-left: auto;
        min-width: 400px;
      }
    }
  }
}
