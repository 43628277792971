.ReactTable .-pagination .-btn {
  height: 38px;
}

.rma-highlights-page .ReactTable .rt-td {
  padding: 0;
}

.rma-highlights-page .level-item {
  margin-bottom: 10px;
}

.colored-number-cell {
  padding-right: 0.5rem;
  text-align: right;
  height: 100%;

  span {
    color: white;
  }
}

.comment-box {
  display:flex;
  flex-wrap:nowrap;
}

.comment-box span.comment {
  padding-left: 3px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
