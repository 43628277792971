.content table td {
  border: none;
}

.message-body {
  white-space: pre-wrap;
}

.note-text {
  width: 445px;
}

.shipping-record {
  .message {
    width: 150%;
    max-width: 100vw;
  }
}

.fault {
  color: #d9534f;
}

.disabled {
  color: rgba(0, 0, 0, 0.6);
}

.is-grey {
  background-color: rgba(0, 0, 0, 0.5);
}

.timeline-item .fail-message {
  max-width: 35rem;
  overflow-x: auto;
  margin-top: 1rem;
}

.device-install-details {
  .message {
    max-width: 100vw;
  }

  .info-table {
    table-layout: fixed;
    min-width: 45rem;

    .info-table-row:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .info-table-item {
      text-align: center;
    }

    .info-table-channel {
      max-width: 3rem;
    }
  }
}

.monospace {
  font-family: monospace;
}

.timeline-content .message {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.device-info-details p {
  margin-bottom: 0.1rem !important;
}
