$brand-color: #64a70b;

$darkgreen: #66b400;
$green: lighten($brand-color, 2%);
$lightgreen: #9bda4a;
$red: #d9534f;
$maroon: #9c0a47;
$brightred: #f70d6f;
$tan: #b7a100;
$gold: #e2ca21;
$yellow: #efdc51;
$darkblue: #0c5972;
$blue: #367d95;
$lightblue: #13c2eb;
$verylightblue: #a6edfe;

$darkgrey: #505759;
$grey: lighten($darkgrey, 20%);
$lightgrey: lighten($grey, 30%);
$verylightgrey: lighten($grey, 40%);

$primary: $green;
$info: #5872b0;
$warning: $tan;

$react-toggle-color: $brand-color;
