.documents-page {
  .ReactTable {
    .-pagination .-btn {
      height: 38px;
    }

    .rt-td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 1px;
      padding-bottom: 1px;

      &.itemTitle {
        width: 30%;
      }

      &.product {
        width: 15%;
      }
    }
  }

  .modal-card {
    width: 700px;
  }
}

.checkbox:not(:first-child) {
  margin-left: 1em;
}

.downvote {
  color: #ff6500;
}

.novote {
  color: #4a4a4a;
}

.better-tooltip {
  width: 325px;
  white-space: pre-wrap;
}
