.documents-page {
  .ReactTable {
    .-pagination .-btn {
      height: 38px;
    }

    .rt-td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 1px;
      padding-bottom: 1px;

      &.itemTitle {
        width: 30%;
      }

      &.product {
        width: 15%;
      }
    }
  }

  .modal-card {
    width: 700px;
  }
}

.checkbox:not(:first-child) {
  margin-left: 1em;
}

.markdown-card {
  overflow-y: auto !important;
  max-height: 90vh;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  h1,
  h2 {
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
  }

  h1 { font-size: 2em; }
  h2 { font-size: 1.5em; }
  h3 { font-size: 1.25em; }
  h4 { font-size: 1em; }
  h5 { font-size: 0.875em; }

  h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  table,
  blockquote,
  p,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 16px;
  }

  table {
    display: block;
    width: 100%;
    overflow: auto;
    border-color: grey;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;

      &:nth-child(2n) {
        background-color: #f6f8fa;
      }
    }

    td,
    th {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }
  }

  blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
  }
}
