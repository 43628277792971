.rma-page table {
  width: 100%;
}

.pre {
  white-space: pre-wrap;
}

.note {
  font-variant: small-caps;
  font-size: 80%;
}

.inactiveLink {
  pointer-events: none;
  cursor: default;
}

.box {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;

    &::after {
      @include loader;

      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}

.contactList {
  list-style: none;
  padding-left: 0;
}
