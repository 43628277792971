
.vs_footer {
  @extend .has-text-centered;

  padding: 1rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9em;

  span {
    display: block;
  }

  a:not(.icon) {
    color: inherit;
    border-bottom: none;

    &:visited,
    &:active {
      color: inherit;
    }
  }
}
