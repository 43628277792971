@import "./overrides";
@import "./spacing";
@import "~bulma/bulma";
@import "~bulma-timeline/dist/bulma-timeline";
@import "~bulma-tooltip/dist/bulma-tooltip";
@import "~bulma-o-steps/bulma-steps";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

$brand-bg-image: linear-gradient(120deg, lighten($brand-color, 6%) 0%, $brand-color 30%, $brand-color 100%);

@import "./styles/colors";
@import "./styles/fonts";
@import "~react-table/react-table.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "src/components/shared/navbar/sidebar";
@import "src/components/shared/navbar/navbar";
@import "./components/shared/footer.scss";
@import "./components/login/login.scss";
@import "./components/device/device.scss";
@import "./components/search/search.scss";
@import "./components/po/po.scss";
@import "./components/rma/rma.scss";
@import "./components/metrics/metrics.scss";
@import "./components/rma_highlights/rma_highlights.scss";
@import "./components/compatibility/compatibility.scss";
@import "./components/documents/documents";
@import "./components/how_to_get_better/how_to_get_better.scss";
@import "./components/ipr_entry/ipr_entry.scss";
@import "./components/rma_entry/rma_entry.scss";
@import "./components/device_configurations/device_configurations";

html {
  background-color: #f2f2f2;
}

html,
body,
input,
textarea,
select,
button {
  font-family: 'Open Sans', sans-serif;
}

.elev-1 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05) !important;
  border: none !important;
}

.rounded {
  border-radius: 0.25rem;
}

.field {
  .input:not(.is-danger),
  .textarea:not(.is-danger) {
    border: none;
    box-shadow: none;
  }

  .select:not(.is-danger) select {
    border: none;
    box-shadow: none;

    &.is-addon-right {
      border-left: 1px solid #eee;
    }
  }
}

.table {
  background-color: transparent;
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  .card-header {
    background-color: white;
    box-shadow: none;
  }

  .card-header-title {
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem 1.5rem 0;
  }

  .vs-title {
    margin-bottom: 0 !important;
    font-size: 1.5rem;
  }

  .vs-subtitle {
    font-size: 1.2rem;
    font-weight: 300;
    color: #999;
    text-transform: uppercase;
  }
}

.modal {
  align-items: flex-start;

  .card,
  .modal-card {
    margin-top: 1rem;
  }

  .modal-card-head {
    background-color: white;
    border: none;

    &.is-danger {
      .modal-card-title {
        color: $danger;
      }
    }
  }

  .modal-card-body {
    background-color: #f2f2f2;

    .input,
    .select,
    .textarea {
      @extend .elev-1, .rounded;
    }
  }

  .modal-card-foot {
    border: none;
    background-color: #f2f2f2;
    justify-content: flex-end;

    .button {
      &.is-text {
        text-decoration: none;
      }

      &:not(.is-text) {
        @extend .elev-1;
      }
    }
  }
}

.ReactTable {
  border: none;

  .rt-thead {
    &.-header {
      box-shadow: none;
    }

    &.-filters input {
      border-radius: 5px;
    }

    .rt-th {
      text-align: left;
      font-weight: 500;
      color: #888f8f;
      display: flex;
      flex-flow: row;
      align-items: center;
      border-right: none;

      &.-sort-asc {
        box-shadow: none !important;

        &::after {
          @extend %fa-icon;
          @extend .fas;

          font-size: 0.75rem;
          margin-left: 0.5rem;
          content: fa-content($fa-var-chevron-up);
        }
      }

      &.-sort-desc {
        box-shadow: none !important;

        &::after {
          @extend %fa-icon;
          @extend .fas;

          font-size: 0.75rem;
          margin-left: 0.5rem;
          content: fa-content($fa-var-chevron-down);
        }
      }
    }
  }

  .rt-tbody {
    .rt-td {
      border-right: none;
    }

    .rt-tr:hover {
      .rt-td {
        background: rgba(255, 255, 255, 0.35);
      }
    }
  }

  .-pagination {
    box-shadow: none;
    border-top: none;
    padding-top: 1rem;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.timeline-item {
  .timeline-marker.is-large-marker {
    @extend .is-icon;

    height: 2.5em;
    width: 2.5em;
    left: -1.18em;
    padding: 0.3em;

    & > i {
      font-size: 1.5em !important;
    }
  }
}

.serial-input {
  flex-grow: 1;
}

.highlight-column {
  .table {
    p { margin-bottom: 2px; }
    .tag { width: 3em; }
  }
}

.heading { @extend .is-size-6-touch; }
.message-header { @extend .is-size-5-touch; }
.message-body { @extend .is-size-5-touch; }

.timeline-content {
  @extend .is-size-5-touch;

  pre {
    @extend .elev-1, .rounded;

    background-color: white;
  }
}

@include touch {
  .control {
    .select select,
    .input,
    .button {
      height: 3em;
    }

    .select:not(.is-multiple)::after {
      margin-top: 0;
    }

    .icon.is-small {
      top: 5px;
    }
  }
}

.page-title {
  font-weight: 500;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.button-no-bg {
  background: none;
  border: none;
}

.button-no-bg:hover {
  cursor: pointer;
  color: black;
}

.button-like-link {
  @extend .button-no-bg;

  color: $blue;
  font-size: 16px;
}

.has-shadow {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2) !important;
}

.no-shadow {
  box-shadow: none !important;
}

.select:not(.is-multiple)::after,
.steps .steps-marker {
  z-index: 0;
}

/* Loader */
.loader-wrapper {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  span {
    color: rgba(0, 0, 0, 0.25);
    font-weight: 300;
  }
}
