.search-result {
  margin-bottom: 17px;
}

.title span.thin-text {
  font-weight: 300;
  color: #4a4a4a;
}

@media print {
  .navbar { display: none; }
  .metric-section { display: none; }
  .level { display: none; }
  form { display: none; }
}
