.login-form {
  p { margin-top: 20px; }
}

.login-button {
  @extend .button;

  display: inline;
}

.login-hero {
  @extend .hero, .is-success, .is-fullheight;

  background-color: $brand-color;
  background-image: url('/assets/glados_bg_transparent.png');
  background-repeat: no-repeat;
  background-size: 40%;

  .hero-body .column { text-align: center; }

  .vs_footer {
    color: white;
    padding-bottom: 1.5rem;
  }
}

@keyframes dark-green-fade {
  from { background-color: darken($brand-color, 10%); }
  to { background-color: transparent; }
}

.login-failed {
  @extend .mll, .phl, .pvs;

  display: inline-block;
  font-size: 1.1rem;
  font-weight: 400;
  color: $light;
  animation: 1s dark-green-fade;
}

.login-link {
  text-align: center;
  display: inline-block;
  margin-top: 45px;
}

@media only screen and (max-width: 1000px) {
  .login-hero {
    background-size: 70%;
  }
}