.navbar {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  z-index: 1;
  color: #4e5658;

  .navbar-btn {
    color: #4a4a4a;

    &:hover {
      background: transparent;
      color: #333;
    }
  }

  .navbar-link {
    color: #726f6f;
  }

  .navbar-item {
    color: #4e5658;

    .navbar-link::after {
      border-color: #64a70b;
    }
  }

  .navbar-dropdown {
    .navbar-item {
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
    }
  }

  .navbar-center {
    flex: 1;
    display: flex;
    flex-flow: row;
    justify-content: center;
    position: relative;
    z-index: 5;

    .search-wrapper {
      flex: 1;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      max-width: 550px;

      .search-panel {
        position: absolute;
        width: 100%;
        max-width: 550px;
        top: 2.5rem;
        background: white;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        z-index: 5;

        .search-buttons {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .button {
            @extend .is-small, .is-light;

            color: $gray-65-brand;
            border-radius: 5px;
            margin-right: 0.5rem;

            &.selected {
              background: $dark-blue-25;
              color: $dark-blue-65;
              font-weight: 600;
            }
          }
        }

        .search-results {
          display: flex;
          flex-flow: column;
          overflow-y: auto;
          max-height: 400px;

          .search-result {
            margin-bottom: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            padding: 0.65rem 0.5rem;
            cursor: pointer;
            display: flex;
            flex-flow: row;

            .result-title {
              font-weight: normal;
              font-size: 1.125rem;
              color: $gray-brand;
              display: flex;
              flex-flow: row;
              align-items: center;
            }

            .result-subtitle {
              font-weight: 200;
              font-size: 1rem;
              color: $gray-65-brand;
            }

            .result-timestamp {
              color: $gray-65-brand;
              text-decoration: none;
              font-weight: 200;
              font-style: italic;
              font-size: 0.9rem;
            }

            &:first-child {
              border-top: none;
              margin-top: 0.5rem;
            }

            &:hover {
              background: rgba(0, 0, 0, 0.02);
            }
          }
        }
      }
    }

    .search-input {
      max-width: 550px;
      width: 100%;

      input {
        border-radius: 0.5rem;
        background: #f2f2f2;
        box-shadow: none;
        color: $gray-65-brand;
      }
    }
  }

  .show-again-checkbox {
    font-style: italic;
    color: $grey;
    font-size: 90%;
  }

  .modified-modal-card-footer {
    background-color: #f2f2f2;
    // stylelint-disable-next-line length-zero-no-unit
    border-radius: 0px 0px 10px 10px;
  }

  i {
    width: 1.1rem;
    text-align: center;
  }
}
