.ReactTable .-pagination .-btn {
  height: 38px;
}

.compatibility-page .ReactTable .rt-td {
  padding: 0;
}

.compatibility-page .modal-card {
  width: 750px;
}
