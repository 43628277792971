.metric-section .level-item {
  width: 33%;
}

.first-run-yield,
.rma-percentage {
  width: 80%;
  margin-bottom: 66px;

  .heading { margin-bottom: 0; }
  p.subtitle { margin-bottom: 15px; }
}

.is-fullwidth {
  width: 100%;
}

.level-item.shipping {
  .title.bumper { margin-bottom: 40px; }
  .recharts-responsive-container { margin-bottom: 66px; }
}

.level-item.rma {
  .subtitle.bumper { margin-bottom: 53px; }
}

p.subtitle.skinny-bumper {
  margin-bottom: 0;
}
